<template>
  <div class="demand">
    <Search
      :search-info="searchInfo"
      :release-info="releaseInfo"
      :check-lawyer="false"
      @search="getData"
    />
    <ConditionSelection
      :options="options"
      :search-info="searchInfo"
      @search="getData"
    />
    <div v-if="officeData.length" v-loading="loading">
      <div class="container">
        <Office
          v-for="(item, index) of officeData"
          :key="item + index"
          :data="item"
          :lable-data="officeLableData"
        />
        <template v-for="(rowItem, rowIndex) in row - (len % row)">
          <div
            v-if="len % row > 0"
            :key="rowItem + rowIndex"
            class="complement"
          ></div>
        </template>
      </div>
      <Pagination class="pagination" :page-info="pageInfo" @changePage="changePage" />
    </div>
    <Empty v-else type="serviceContent" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import Office from '@/components/Service/ServiceOffice'
import ConditionSelection from '@/components/ConditionSelection'
import Search from '@/components/Service/ServiceSearch'
import Empty from "@/components/Empty/Factory"
import { mapGetters, mapActions } from 'vuex'
import API from '@/api'
export default {
  name: 'Demand',
  components: {
    ConditionSelection,
    Search,
    Pagination,
    Office,
    Empty,
  },
  data() {
    return {
      loading: true,
      row: 4,
      searchInfo: {
        country: '',
        province: '',
        keyword: '',
        type: '',
        businessTypeId: '',
        urgentStatus: '',
      },
      pageInfo: {
        currentPage: 1,
        size: 12,
        total: 1,
      },
      releaseInfo: {
        title: '7e0fbbd',
        pla: 'e003ff6',
        path: '/service/demand/release',
      },
      officeData: [],
      officeLableData: [
        {
          lable: '发布时间',
          key: 'createTime',
        },
        {
          lable: '发布单位',
          key: 'name',
        },
        {
          lable: '所属区域',
          key: 'area',
        },
        {
          lable: '涉及区域',
          key: ['country', 'province'],
        },
        {
          lable: '需求类型',
          key: 'businessType',
        },
        {
          lable: '需求内容',
          key: 'content',
        },
        {
          lable: '需求状态',
          key: 'urgentStatus',
        },
      ],
      options: [
        {},
        {
          name: 'bddb155',
          key: 'type',
          list: [
            {
              name: '法律需求',
              id: 'LAW',
              child: {},
            },
            {
              name: '翻译需求',
              id: 'TRANSLATE',
              child: {},
            },
            // {
            //   name: '其他需求',
            //   id: 'OTHER',
            // },
          ],
        },
        {
          name: '5237d0d',
          key: 'urgentStatus',
          list: [
            {
              name: '紧急',
              id: 'EMERGENCY',
            },
            {
              name: '正常',
              id: 'NORMAL',
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['lawRequirementsObj', 'dictionaryList', 'provinceList']),
    len() {
      return this.officeData.length
    },
  },
  // beforeRouteLeave(to, from, next) {
  //   this.options = []
  //   console.log('this.options', this.options)
  //   next((vm) => {})
  // },
  watch: {
    lawRequirementsObj(newVal) {
      this.loading = false
      if (newVal) {
        this.officeData = newVal.content
        this.pageInfo.total = newVal.page.total
      }
    },
    dictionaryList(newVal) {
      if (newVal) {
        const list = newVal.content
        if (list[0].name === '中国') {
          list[0].child = {
            key: 'province',
            list: this.provinceList,
          }
        }

        this.options.splice(0, 1, {
          name: '70822d6',
          key: 'country',
          list: newVal.content,
        })
      }
    },
  },
  async created() {
    this.getData()
    this.getTypeList()
    await this.getProvinceList({
      selectType: 'LAW_REQUIREMENTS'
    })
    this.getDictionaryList({
      type: 'LAW_REQUIREMENTS',
    })
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeLabelData(to, from)
    })
  },
  methods: {
    ...mapActions([
      'getLawRequirements',
      'getDictionaryList',
      'getProvinceList',
    ]),
    resetData() {
      this.pageInfo = {
        currentPage: 1,
        size: 12,
        total: 1,
      }
    },
    async changeLabelData(to, from) {
      if (from.path !== '/service/demand/details') {
        this.resetData()
        this.getData()
      }
    },
    getData() {
      this.loading = true
      this.getLawRequirements({
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size,
        country: this.searchInfo.country,
        province: this.searchInfo.province,
        keyword: this.searchInfo.keyword,
        type: this.searchInfo.type,
        businessTypeId: this.searchInfo.businessTypeId,
        urgentStatus: this.searchInfo.urgentStatus,
      })
    },
    getTypeList() {
      API.common
        .getDictionaryList({
          type: 'BUSINESS_TYPE',
        })
        .then((res) => {
          const list = res.content
          this.options[1].list[0].child = {
            key: 'businessTypeId',
            list,
          }
        })
      API.common
        .getDictionaryList({
          type: 'TRANSLATION_BUSINESS_TYPE',
        })
        .then((res) => {
          const list = res.content
          this.options[1].list[1].child = {
            key: 'businessTypeId',
            list,
          }
        })
    },
    changePage(page) {
      this.pageInfo.currentPage = page
      this.getData()
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.demand {
  width: @uni-width;
  margin: 0 auto;
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .complement {
      width: 285px;
      height: 368px;
    }
  }
  .pagination {
    margin-bottom: 20px;
  }
}
</style>
